import { ConstraintTypes, IConstraint } from "../types/businessType";
import { ICategory } from "../types/category";

export const categories: ICategory[] = [
    {
        name: "Residential",
        businessTypes: [
            { id: 2, name: "Hotel" },
            { id: 3, name: "Motel" },
            { id: 4, name: "Bed and Breakfast" },
            { id: 5, name: "Short Term Rental (AirBnb, etc.)" },
            { id: 6, name: "Residential Compound" },
            { id: 7, name: "Religious Housing" }
        ]
    },
    {
        name: "Retail",
        businessTypes: [
            { id: 8, name: "General Retail" },
            { id: 9, name: "Building Materials Retail" },
            { id: 10, name: "Automotive" },
            { id: 11, name: "Liquor Store" },
            { id: 12, name: "Marine Products" },
            { id: 13, name: "Seafood" },
            { id: 14, name: "Gas Station" }
        ]
    },
    {
        name: "Office",
        businessTypes: [
            { id: 15, name: "General" },
            { id: 16, name: "Dental" },
            { id: 17, name: "Medical" },
            { id: 18, name: "Veterinary" }
        ]
    },
    {
        name: "Services",
        businessTypes: [
            { id: 19, name: "General Services" },
            { id: 1, name: "Restaurant" },
            { id: 20, name: "Automotive Services" },
            { id: 21, name: "Day Care" },
            { id: 22, name: "Religious Services" },
            { id: 23, name: "Motor Vehicle Rental Service" },
            { id: 24, name: "Salon/Barber" },
            { id: 25, name: "Drug or Alcohol Rehab Facility" },
            { id: 26, name: "Service Organization" },
            { id: 35, name: "Construction Service" }
        ]
    },
    {
        name: "Entertainment",
        businessTypes: [
            { id: 27, name: "Live Theater" },
            { id: 28, name: "Movie Theater" },
            { id: 29, name: "Marina/Yacht Club" },
            { id: 30, name: "Shooting Range" },
            { id: 31, name: "Tennis Courts" },
            { id: 32, name: "Golf Course" },
            { id: 33, name: "Sports Facility" },
            { id: 34, name: "Concert Hall" }
        ]
    }
];

export const constraints: IConstraint[] = [
    { type: ConstraintTypes.RoomCount, btid: 2 },
    { type: ConstraintTypes.RoomCount, btid: 3 },
    { type: ConstraintTypes.RoomCount, btid: 4 },
    { type: ConstraintTypes.Sqft, btid: 8 },
    { type: ConstraintTypes.Sqft, btid: 9 },
    { type: ConstraintTypes.Sqft, btid: 15 },
    { type: ConstraintTypes.Alcohol, btid: 1 },
    { type: ConstraintTypes.HeadCount, btid: 21 },
    { type: ConstraintTypes.Indoors, btid: 35 },
    { type: ConstraintTypes.Alcohol, btid: 27 },
    { type: ConstraintTypes.Indoors, btid: 30 },
    { type: ConstraintTypes.Alcohol, btid: 32 },
    { type: ConstraintTypes.Indoors, btid: 33 }
];