import React from "react";
import { IBusinessType } from "../types/businessType";
import { BusinessType } from "./businessType";

interface Props { 
    name: String;
    businessTypes: IBusinessType[];
}

export const Category = ({name, businessTypes}: Props) => { 
    return (    
    <div>
    <p className='font-bold text-lg'>{name}</p>
        {
            businessTypes.map((t) => { 
                return (
                    <BusinessType name={t.name} key={t.id} btid={t.id} />
                )
            })
        }
    </div>
    );
}