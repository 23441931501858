  // mui only lets you use dumb ass "palette" to specify color for mui components 
  // do not use this for dark mode switching, use react context + tailwind 

import { createTheme } from "@mui/material";

  // sundar pinchai can suck my cock
const muiTheme = createTheme({
  palette: {
      primary: {
        main: "#4aa3df",
        dark: "#4aa3df",
        light: "#4aa3df"
      },
      secondary: { 
        main:'#ffffff',
        dark:'#ffffff',
        light:'#ffffff'
      }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Stops uppercasing button text
        },
      },
    },
  },
});

export default muiTheme; 