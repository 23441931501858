import { Button } from "@mui/material";
import React from "react";
import { useBusinessTypeContext } from "../hooks/useBusinessTypeContext";

interface IProps { 
    setSearchResults: (results: string[]) => void;
}

export const SearchButton = ({setSearchResults}: IProps) => { 
    const { fetchAllowedAddresses } = useBusinessTypeContext();

    const handleClick = async() => { 
        const allowedAddresses = await fetchAllowedAddresses();
        setSearchResults(allowedAddresses)
    }

    return (
    <div className="flex justify-center py-4 mt-8">
        <Button
            color="primary"
            variant="contained"
            onClick={() => handleClick()}
            sx={{
                fontSize: "1.5rem",
                padding: "1rem 2rem",
                minWidth: "150px",
            }}
            >
            Search
        </Button>
    </div>);
};