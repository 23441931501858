import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface IProps { 
    allowedAddresses: string[];
}

export const SearchResults = ({allowedAddresses}: IProps) => { 

    const columns: GridColDef<(typeof rows)[number]>[] = [
        { field: 'address', headerName: 'Address', width: 400 }, 
    ];

    const rows = allowedAddresses.map((address, id) => {
        return { id, address }; 
    });

    return ( 
        <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
            }}
            sx={{
                ".MuiDataGrid-cell": {        // Customize cell text color
                    color: "white",
                },
                ".MuiDataGrid-footerContainer": { // Customize footer container (pagination area)
                    color: "white",
                },
                ".MuiTablePagination-root": { // Customize pagination root
                    color: "white",
                },
                ".MuiTablePagination-actions": { // Customize pagination buttons
                    button: {
                        color: "white",
                    },
                },
            }}
        />
    );

}