import React, { useState } from 'react';
import logo from './logo.svg';
import { ThemeProvider } from '@mui/material';
import { categories } from './util/constants';
import { Category } from './components/category';
import muiTheme from './muiTheme';
import { BusinessTypeProvider } from './contexts/businessTypeContextProvider';
import { SearchButton } from './components/searchButton';
import { SearchResults } from './components/searchResults';

function App() {
  const [searchResults, setSearchResults] = useState([] as string[])

  if(searchResults.length > 0) { 
    return ( 
      <ThemeProvider theme={muiTheme}>
        <BusinessTypeProvider>
          <div className="min-h-screen w-full bg-gray-900 text-white">
            <header>
              <img src={logo} width={"350rem"} height={"auto"} alt="brook" className="pb-4" />
            </header>
            <div>
              <SearchResults allowedAddresses={searchResults} />
            </div>
            <SearchButton setSearchResults={setSearchResults} />
          </div>
        </BusinessTypeProvider>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={muiTheme}>
      <BusinessTypeProvider>
        <div className="min-h-screen w-full bg-gray-900 text-white">
          <header>
            <img src={logo} width={"350rem"} height={"auto"} alt="brook" className="pb-4" />
          </header>
          <div className="flex flex-row flex-wrap gap-4 justify-center">
            {categories.map((cat, i) => (
              <Category name={cat.name} businessTypes={cat.businessTypes} key={i} />
            ))}
          </div>
          <SearchButton setSearchResults={setSearchResults} />
        </div>
      </BusinessTypeProvider>
    </ThemeProvider>
  );
}

export default App;
