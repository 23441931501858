import { useContext } from "react";
import { BusinessTypeContext } from "../contexts/businessTypeContextProvider";

  
// Custom hook for accessing the context
export const useBusinessTypeContext = () => {
    const context = useContext(BusinessTypeContext);
    if (!context) {
        throw new Error('useBusinessTypeContext must be used within a BusinessTypeProvider');
    }
    return context;
};