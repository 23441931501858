export enum ConstraintTypes {
    Sqft = "sqft",
    Indoors = "indoors",
    HeadCount = "headCount",
    Alcohol = "alcohol",
    RoomCount = "roomCount",
}

export type IConstraint = 
{ 
    btid: number;
    type: ConstraintTypes.Sqft;
    sqft?: number;
} |
{
    btid: number;
    type: ConstraintTypes.Indoors
    indoorsOnly?: boolean;
    outdoorsOnly?: boolean;
} | 
{
    btid: number;
    type: ConstraintTypes.HeadCount
    headCount?: number;
} | 
{
    btid: number;
    type: ConstraintTypes.Alcohol
    withAlcohol?: boolean;
    withoutAlcohol?: boolean;
} | 
{
    btid: number;
    type: ConstraintTypes.RoomCount
    roomCount?: number
};

export interface IBusinessType { 
    id: number;
    name: string;
    constraints?: IConstraint[];
}