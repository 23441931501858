import React, { createContext, useState } from "react";
import { ConstraintTypes, IBusinessType, IConstraint } from "../types/businessType";
import { constraints } from "../util/constants";

interface IBusinessTypeContext {
    selectedBusinessTypes: IBusinessType[];
    addBusinessType: (businessType: IBusinessType) => void;
    removeBusinessType: (businessTypeId: number) => void;
    fetchAllowedAddresses: () => Promise<string[]>; 
  }

export const BusinessTypeContext = createContext<IBusinessTypeContext | null>(null);

export const BusinessTypeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [selectedBusinessTypes, setSelectedBusinessTypes] = useState<IBusinessType[]>([]);

    const addBusinessType = (businessType: IBusinessType) => {
        let updatedBusinessType = {...businessType};
        let btConstraints = constraints.filter(constraint => constraint.btid === businessType.id);
        let newBtConstraintsList: IConstraint[] = [];

        if(btConstraints.length > 0) { 
            newBtConstraintsList = btConstraints.map(btConstraint => {
                switch( btConstraint.type) { 
                    case ConstraintTypes.Alcohol: 
                    const servesAlc = window.prompt(`Will the ${businessType.name} serve alcohol? Y/n `) !== "N";
                    return ({...btConstraint, withAlcohol: servesAlc, withoutAlcohol: !servesAlc});

                    case ConstraintTypes.HeadCount:
                    const headCount = parseInt(window.prompt(`How many people will the ${businessType.name} serve at a time?`) ?? "1");
                    return {...btConstraint, headCount}

                    case ConstraintTypes.Indoors:
                    const indoorsOnly = window.prompt(`Will the ${businessType.name} be indoors? Y/n `) !== "N";
                    return ({...btConstraint, indoorsOnly, outdoorsOnly: !indoorsOnly});

                    case ConstraintTypes.RoomCount:
                    const roomCount = parseInt(window.prompt(`How many rooms will the ${businessType.name} offer? \n (This does not search properties by room count(yet), only what they're zoned for)`) ?? "1");
                    return {...btConstraint, roomCount}

                    case ConstraintTypes.Sqft:
                    const sqft = parseInt(window.prompt(`How many sqft is the ${businessType.name} offer? \n (This does not search properties by sqft(yet), only what they're zoned for)`) ?? "1");
                    return {...btConstraint, sqft}

                    default: 
                    return btConstraint;
                }
            });
        }

        updatedBusinessType = {...updatedBusinessType, constraints: newBtConstraintsList}
        setSelectedBusinessTypes((prev) => [...prev, updatedBusinessType]);
    };

    const removeBusinessType = (businessTypeId: number) => {
        setSelectedBusinessTypes((prev) => prev.filter((bt) => bt.id !== businessTypeId));
    };

    const fetchAllowedAddresses = async (): Promise<string[]> => {
        const url = `${window.location.origin}/api/search`;
    
        const data = {
            businessTypes: selectedBusinessTypes
        };
    
        try {
            const response = await postData(url, data);
            console.log('Server Response:', response);
            return response.results ?? [] as string[]; // Return the results
        } catch (error) {
            console.error('Error:', error);
            throw error; // Rethrow the error to propagate it to the caller
        }
    };

    return (
        <BusinessTypeContext.Provider value={{ selectedBusinessTypes, addBusinessType, removeBusinessType, fetchAllowedAddresses }}>
          {children}
        </BusinessTypeContext.Provider>
    );
};

async function postData(url: string, data: Record<string, any>): Promise<any> {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
}