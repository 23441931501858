import React, {useState} from "react";
import { Checkbox } from "@mui/material";
import { useBusinessTypeContext } from "../hooks/useBusinessTypeContext";

// key and btid are identical because any prop called "key" implicitly gets passed as undefined, but prop called key is required to uniquely identify shadow dom elements under react hood
// thank you zuckerfuck
interface Props { 
  name: string;
  btid: number;
}

export const BusinessType = ({ name, btid}: Props) => { 
  const [checked, setChecked] = useState(false);
  const { addBusinessType, removeBusinessType } = useBusinessTypeContext();

  const handleChange = (isChecked: boolean) => { 
    setChecked(isChecked);
    isChecked ? addBusinessType({id: btid, name}) : removeBusinessType(btid);
  }

  return ( 
    <div className="flex flex-row pl-4">
      <Checkbox
        color="primary"
        checked={checked}
        onChange={(e, isChecked) => handleChange(isChecked)}
        sx={{
          "&.MuiCheckbox-root": {
          color: "#4aa3df", // Default outline color
          },
          "&.Mui-checked": {
          color: "#4aa3df", // Checked outline color
          },
          "&.MuiCheckbox-root:hover": {
          color: "#4aa3df", // Hover color for secondary
          },
        }}
      />
        <p className="pt-2">{name}</p>
    </div>
  )
}